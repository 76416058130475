import React, { Component, useRef, useFrame } from "react";
import Layout from "../layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HTMLContent from "../components/htmlcontent";
import _ from "lodash";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changePic: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      () => {
        this.setState({
          changePic: window.scrollY >= 10
        });
      }
    );
  }

  render() {
    const aboutHtml = this.props.data.markdownRemark.html;
    const pic1 = this.props.data.pic1.childImageSharp.gatsbyImageData;
    const pic2 = this.props.data.pic2.childImageSharp.gatsbyImageData;
    const friends = this.props.data.friendnet.frontmatter.content;
    const eeto = this.props.data.eeto.frontmatter.content;
    return (
      <Layout>
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column" style={{ wordBreak: "break-word" }}>
                  <h1 className="title is-size-2">
                    welcome to <span className="has-text-primary">trinity</span>
                    's corner of the internet
                  </h1>

                  <div className="is-size-5">
                    {<HTMLContent html={aboutHtml} />}
                  </div>
                </div>
                <div className="column">
                  {this.state.changePic ? (
                    <GatsbyImage image={pic2} />
                  ) : (
                    <GatsbyImage image={pic1} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <h3>friendnet</h3>
          <p>
            {friends.map((f) => (
              <span>
                <a href={f.site}>
                  {f.name}
                </a>{" "}
                ~{" "}
              </span>
            ))}
          </p>
          <p class="eeto">
            {eeto.map((f) => (
              <a href={f.site}>
                <img src={"/img/8831/" + f.eeto}></img>
              </a>
            ))}
          </p>
        </section>
      </Layout>
    );
  }
}

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { for: { eq: "about" } }) {
      html
    }
    pic1: file(name: { eq: "2023-cornell" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
    pic2: file(name: { eq: "2024-blahaj" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
    friendnet: markdownRemark(frontmatter: { for: { eq: "friendnet" } }) {
      frontmatter {
        content {
          name
          site
        }
      }
    }
    eeto: markdownRemark(frontmatter: { for: { eq: "eeto" } }) {
      frontmatter {
        content {
          eeto
          site
        }
      }
    }
  }
`;
